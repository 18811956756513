import PT from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setBKFAttributes, setBKFProp } from '@State/bkf/actions';
import { NEW_BOOKING, isBookingCancelled, isBookingNew } from '@Utils/booking-util';
import {
  bookingAttributeChanged, bookingStatusChanged,
  changeBookingAttribute, changeBookingStatus
} from '@State/booking-actions';
import { txt } from '@Utils/i18n-util';
import msg from './booking-flags.msg';

class BookingFlags extends Component {
  static propTypes = {
    id: PT.oneOfType([
      PT.string,
      PT.number
    ]).isRequired,
    status: PT.string.isRequired,
    askedForPerson: PT.bool.isRequired,
    dropIn: PT.bool.isRequired,
    onStatusChange: PT.func.isRequired,
    onFlagChange: PT.func.isRequired
  };

  handleAFPClick = () => {
    const { id, askedForPerson, onFlagChange, customerIds } = this.props;
    onFlagChange(id, customerIds, { askedForPerson: !askedForPerson });
  };

  handleDropInClick = () => {
    const { id, dropIn, onFlagChange, customerIds } = this.props;
    onFlagChange(id, customerIds, { dropIn: !dropIn });
  };

  handleShowClick = () => {
    const { id, status, onStatusChange, customerIds } = this.props;
    onStatusChange(id, customerIds, status === 'Show' ? 'Booked' : 'Show');
  };

  handleNoShowClick = () => {
    const { id, status, onStatusChange, customerIds } = this.props;
    onStatusChange(id, customerIds, status === 'NoShow' ? 'Booked' : 'NoShow');
  };

  render() {
    const { askedForPerson, dropIn, status } = this.props;

    const isNew = isBookingNew(this.props);
    const isCancelled = isBookingCancelled(this.props);
    const baseClasses = 'label label-default';
    const askedForClasses = baseClasses + (askedForPerson ? ' active label-asked-for-person' : '');
    const dropInClasses = baseClasses + (dropIn ? ' active label-drop-in' : '');
    const showClasses = baseClasses + (status === 'Show' ? ' active label-status-show' : '');
    const noShowClasses = baseClasses + (status === 'NoShow' ? ' active label-status-no-show' : '');

    return (
      <div className={isCancelled ? 'booking-form-flags disabled' : 'booking-form-flags'}>
        {!isNew && (
          <div className="pull-right">
            <span className={showClasses} onClick={this.handleShowClick}>{txt(msg.showLabel)}</span> <span className={noShowClasses} onClick={this.handleNoShowClick}>{txt(msg.noShowLabel)}</span>
          </div>
        )}
        <div>
          <span className={askedForClasses} onClick={this.handleAFPClick}>{txt(msg.askedForLabel)}</span> <span className={dropInClasses} onClick={this.handleDropInClick}>{txt(msg.dropInLabel)}</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf } = state;
  const { askedForPerson, dropIn } = bkf.get('attributes') || {};
  const customerIds = bkf.get('customers')?.map(c => c.id).toArray() || [];

  return {
    id: bkf.get('id'),
    status: bkf.get('status'),
    customerIds,
    askedForPerson,
    dropIn
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onStatusChange: (id, customerIds, status) => {
      dispatch(setBKFProp('status', status));

      const change = { bookingId: id, customerIds, status };
      if (id !== NEW_BOOKING) {
        dispatch(changeBookingStatus(change));
      } else {
        dispatch(bookingStatusChanged(change));
      }
    },
    onFlagChange: (id, customerIds, flags) => {
      dispatch(setBKFAttributes({ ...flags }));

      const change = { bookingId: id, customerIds, ...flags };
      if (id !== NEW_BOOKING) {
        dispatch(changeBookingAttribute(change));
      } else {
        dispatch(bookingAttributeChanged(change));
      }
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingFlags);
