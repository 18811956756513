import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { deleteBooking, clearAndCloseBKF, toggleBKFModal } from '@State/bkf/actions';
import { bkfModals } from '@State/bkf/constants';
import { formatPhoneNumberE164 } from '@Utils/phone-util';
import { getFeatures, getSmsEnabled } from '@State/selectors';
import { fetchPosOrgs } from '@State/pos-config-actions';
import {
  cancelBooking, refundBooking, sendBookingConfirmation, sendBookingReceipt
} from '@State/booking-actions';
import { isBookingCancelled, isBookingReservation, isSimpleBooking } from '@Utils/booking-util';
import { addToClipboard } from '@State/clipboard-actions';
import { txt } from '@Utils/i18n-util';
import SendSmsModal from '@Components/customers/customer-detail/send-sms/send-sms-modal';
import ConfirmDelete from './confirm-delete';
import ConfirmCancel from './confirm-cancel';
import ConfirmRefund from './confirm-refund';
import SendConfirmation from './send-confirmation';
import SendReceipt from './send-receipt';
import msg from './booking-options.msg';

class BookingOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 'Menu',
      progress: false
    };
  }

  componentDidMount() {
    const { enablePos, posOrgs } = this.props;
    if (enablePos && posOrgs.isEmpty()) {
      this.props.fetchPosOrgs();
    }
  }

  showMenu = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.setState({ view: 'Menu' });
  };

  showConfirmDelete = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.setState({ view: 'ConfirmDelete' });
  };

  deleteBooking = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();

    this.setState({ progress: true });
    this.props.deleteBooking(this.props.id)
      .catch(() => this.setState({ progress: false }));
  };

  showConfirmCancel = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.setState({ view: 'ConfirmCancel' });
  };

  cancelBooking = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();

    const form = ev.target;
    const options = {
      notifyBySms: form.notifyBySms?.checked,
      notifyByEmail: form.notifyByEmail?.checked,
      deleteBooking: form.deleteBooking?.checked,
      overrideCancellationPolicy: form.overrideCancellationPolicy?.checked
    };

    this.setState({ progress: true });
    const { id, customer } = this.props;
    const customerIds = customer ? [customer.customerId] : [];
    this.props.cancelBooking({ bookingId: id, customerIds, options })
      .catch(() => this.setState({ progress: false }));
  };

  showConfirmRefund = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.setState({ view: 'ConfirmRefund' });
  };

  refundBooking = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();

    this.setState({ progress: true });
    this.props.refundBooking(this.props.id)
      .catch(() => this.setState({ progress: false }));
  };

  showSendSms = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.props.onToggleSendSmsModal(true);
  };

  hideSendSms = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.props.onToggleSendSmsModal(false);
  };

  showSendConfirmation = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.setState({ view: 'SendConfirmation' });
  };

  sendConfirmation = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();

    const form = ev.target;
    const options = {
      sms: form.sms && form.sms.checked,
      email: form.email && form.email.checked
    };

    if (!options.sms && !options.email) return;

    this.setState({ progress: true });
    const { id, customer } = this.props;
    const customerIds = customer ? [customer.customerId] : [];
    this.props.sendBookingConfirmation({ bookingId: id, customerIds, options })
      .then(() => this.props.onClose())
      .catch(() => this.setState({ progress: false }));
  };

  showSendReceipt = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.setState({ view: 'SendReceipt' });
  };

  sendReceipt = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();

    const form = ev.target;
    const payment = this.getPayment();

    const data = {
      paymentRef: payment.paymentRef,
      toName: form.name && form.name.value,
      toEmail: form.email && form.email.value
    };

    this.setState({ progress: true });
    this.props.sendBookingReceipt(data)
      .then(() => this.props.onClose())
      .catch(() => this.setState({ progress: false }));
  };

  printBooking = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.props.printBooking(this.props.id);
    this.props.onClose();
  };

  copyBooking = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.props.copyBooking(this.props.id, this.props.sourceResourceId);
  };

  moveBooking = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.props.moveBooking(this.props.id, this.props.sourceResourceId);
  };

  showEvents = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.props.onShowEvents(true);
  };

  registerPayment = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.props.onRegisterPayment();
    this.props.onClose();
  };

  onClose = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.props.onClose();
  };

  getPayment = () => {
    return this.props.payments?.find(
      p => p.paymentType === 'Online' || p.paymentType === 'PrePaid'
    );
  };

  renderContent() {
    const { view, progress } = this.state;
    const {
      startTime, customer, sale, locationConfig,
      smsEnabled, showSendSmsModal, enablePos, enablePrepaid
    } = this.props;

    const isBooking = isSimpleBooking(this.props);
    const isReservation = isBookingReservation(this.props);
    const isCancelled = isBookingCancelled(this.props);
    const isPastBooking = isBooking && moment().isAfter(startTime);
    const isLocked = isCancelled || isPastBooking || customer?.deleted;
    const canDelete = isPastBooking || isCancelled || !customer;
    const payment = this.getPayment();
    const isPaid = payment?.paymentStatus === 'Paid';
    const isNotRefunded = payment?.paymentStatus !== 'Refunded' && payment?.paymentType === 'Online';

    if (view === 'ConfirmDelete') {
      return <ConfirmDelete onConfirm={this.deleteBooking} onCancel={isReservation ? this.onClose : this.showMenu} progress={progress} />;
    }
    if (view === 'ConfirmCancel') {
      return <ConfirmCancel onConfirm={this.cancelBooking} onCancel={this.showMenu} progress={progress} customer={customer} locationConfig={locationConfig} smsEnabled={smsEnabled} startTime={startTime} payments={payments} />;
    }
    if (view === 'ConfirmRefund') {
      return <ConfirmRefund onConfirm={this.refundBooking} onCancel={this.showMenu} progress={progress} />;
    }
    if (view === 'SendConfirmation') {
      return <SendConfirmation onConfirm={this.sendConfirmation} onCancel={this.showMenu} progress={progress} customer={customer} locationConfig={locationConfig} smsEnabled={smsEnabled} />;
    }
    if (view === 'SendReceipt') {
      return <SendReceipt onConfirm={this.sendReceipt} onCancel={this.showMenu} progress={progress} customer={customer} locationConfig={locationConfig} smsEnabled={smsEnabled} />;
    }

    return (
      <div>
        {showSendSmsModal && (
          <SendSmsModal customerId={customer.customerId} phoneNumber={customer.phoneNumber} onClose={this.hideSendSms} />
        )}
        {isPastBooking && <p className="text-muted"><em>{txt(msg.txtTimePastWarning)}</em></p>}
        {customer?.deleted && <p className="text-muted"><em>{txt(msg.txtCustomerDeleted)}</em></p>}

        {this.renderMobileButtons()}
        {smsEnabled && customer && customer.phoneNumber && (
        <button className="btn-option" onClick={this.showSendSms}>
          <i className="far fa-mobile" /> {txt(msg.btnSendSms)}
        </button>
        )}
        {isBooking && !isLocked && customer && (
        <button className="btn-option" onClick={this.showSendConfirmation}>
          <i className="far fa-paper-plane" /> {txt(msg.btnSendConfirmation)}
        </button>
        )}
        {isBooking && enablePos && enablePrepaid && !sale && (
          <button className="btn-option" onClick={this.registerPayment}>
            <i className="far fa-circle-dollar-to-slot" /> {txt(msg.btnRegisterPayment)}
          </button>
        )}
        {isPaid && (
        <button className="btn-option" onClick={this.showSendReceipt}>
          <i className="far fa-receipt" /> {txt(msg.btnSendReceipt)}
        </button>
        )}
        {isNotRefunded && (
        <button className="btn-option" onClick={this.showConfirmRefund}>
          <i className="far fa-arrow-circle-left" /> {txt(msg.btnRefund)}
        </button>
        )}
        <button className="btn-option mb2" onClick={this.showEvents}>
          <i className="far fa-clock-rotate-left" /> {txt(msg.btnShowEvents)}
        </button>
        {isBooking && (
        <button className="btn-option" onClick={this.printBooking}>
          <i className="fa fa-print" /> {txt(msg.btnPrintBooking)}
        </button>
        )}
        <button className="btn-option" onClick={this.copyBooking}>
          <i className="far fa-copy" /> {txt(msg.btnCopyBooking)}
        </button>
        {!isLocked && (
        <button className="btn-option" onClick={this.moveBooking}>
          <i className="fa fa-cut" /> {txt(msg.btnMoveBooking)}
        </button>
        )}
        {isBooking && !isLocked && customer && (
        <button className="btn-option btn-danger" onClick={this.showConfirmCancel}>
          <i className="fa fa-user-times" /> {txt(msg.btnCancelBooking)}
        </button>
        )}
        {canDelete && (
        <button className="btn-option btn-danger" onClick={this.showConfirmDelete}>
          <i className="fa fa-trash" /> {txt(msg.btnDeleteBooking)}
        </button>
        )}
        <button className="btn-option mt1" onClick={this.onClose}>{txt(msg.btnCancel)}</button>
      </div>
    );
  }

  renderMobileButtons() {
    const { deviceType, customer } = this.props;
    const isMobile = deviceType === 'mobile';
    const phoneNumber = customer && customer.phoneNumber;
    const e164phoneNumber = formatPhoneNumberE164(phoneNumber);

    return isMobile ? (
      <>
        {phoneNumber && (
        <a href={`tel:${e164phoneNumber}`} className="btn-option">
          <i className="fa fa-phone" /> {txt(msg.btnCall)} {phoneNumber}
        </a>
        )}
      </>
    ) : null;
  }

  render() {
    return (
      <div className="booking-options-container">
        <div className="booking-form-header mobile">
          <div className="cancel">
            <a href="#" onClick={this.onClose}>
              <i className="fa fa-chevron-left" /> {txt(msg.btnBack)}
            </a>
          </div>
          <h4 className="title">{txt(msg.lblOptionslTitle)}</h4>
          <div className="save" />
        </div>
        <div className="booking-options-content">
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf, locationConfig, mainViewState, posOrgs } = state;
  const features = getFeatures(state);

  return {
    posOrgs,
    locationConfig,
    id: bkf.get('id'),
    sourceResourceId: bkf.get('sourceResourceId'),
    startTime: bkf.get('startTime'),
    customer: bkf.get('customer'),
    sale: bkf.get('sale'),
    payments: bkf.get('payments'),
    deviceType: mainViewState.get('deviceType'),
    showSendSmsModal: bkf.get('showModal') === bkfModals.sms,
    smsEnabled: getSmsEnabled(state),
    enablePos: features.EnablePOS,
    enablePrepaid: features.EnablePrepaidBookings,
    status: bkf.get('status'),
    type: bkf.get('type')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPosOrgs: () => dispatch(fetchPosOrgs()),
    onShowEvents: () => dispatch(toggleBKFModal(bkfModals.events, true)),
    onRegisterPayment: () => dispatch(toggleBKFModal(bkfModals.payment, true)),
    onToggleSendSmsModal: (show) => dispatch(toggleBKFModal(bkfModals.sms, show)),
    sendBookingConfirmation: data => dispatch(sendBookingConfirmation(data)),
    sendBookingReceipt: data => dispatch(sendBookingReceipt(data)),
    printBooking: () => dispatch(toggleBKFModal(bkfModals.print, true)),
    copyBooking: (id, sourceResourceId) => {
      dispatch(addToClipboard(id, true, sourceResourceId));
      dispatch(clearAndCloseBKF());
    },
    moveBooking: (id, sourceResourceId) => {
      dispatch(addToClipboard(id, false, sourceResourceId));
      dispatch(clearAndCloseBKF());
    },
    cancelBooking: (data) => {
      return dispatch(cancelBooking(data))
        .then(() => dispatch(clearAndCloseBKF()));
    },
    deleteBooking: (id) => {
      return dispatch(deleteBooking(id))
        .then(() => dispatch(clearAndCloseBKF()));
    },
    refundBooking: (id) => {
      return dispatch(refundBooking(id))
        .then(() => dispatch(clearAndCloseBKF()));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingOptions);
